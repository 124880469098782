import React from "react"

import { Text } from "grommet"

const Label = ({ children, as }) => {
  return (
    <Text size="small" weight="500" color="brand" as={as}>
      {children}
    </Text>
  )
}

Label.propTypes = {}

export default Label
