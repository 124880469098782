import React from "react"
import { TableHeader as GrommetTableHeader } from "grommet"

const TableHeader = props => {
  return <GrommetTableHeader {...props} />
}

TableHeader.propTypes = {}

export default TableHeader
