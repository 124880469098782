import React from "react"
import { DropButton, List, Box } from "grommet"
import Label from "./Label"
import LinkAnchor from "./LinkAnchor"

const LinkGroup = ({ title, link, ...props }) => {
  return (
    <DropButton
      {...props}
      style={{ display: "flex" }}
      plain
      label={<Label>{title}</Label>}
      dropAlign={{ top: "bottom" }}
      dropContent={
        <List
          data={link}
          border={false}
          pad={{ vertical: "hair", horizontal: "medium" }}
          primaryKey={({ label, href }) => (
            <Box pad="medium">
              <LinkAnchor href={href} label={label} />
            </Box>
          )}
        />
      }
    />
  )
}

LinkGroup.propTypes = {}

export default LinkGroup
