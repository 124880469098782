import React from "react"

import { navigate, Link } from "gatsby"
import { Anchor as GrommetAnchor, Box } from "grommet"
import styled from "styled-components"
import usePageContext from "../../hooks/usePageContext"
import { useStaticQuery, graphql } from "gatsby"

const StyledLink = styled(Link)`
  text-decoration: none;
  box-shadow: none;
`

const StyledAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
`

const Anchor = ({ href = "", children, as, label, ...rest }) => {
  const {
    strapiGlobal: { localise_pages },
  } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        localise_pages
      }
    }
  `)

  // hash to enable id linking
  const isHref =
    href?.includes("http") ||
    href?.includes("#") ||
    href?.includes("tel:") ||
    href?.includes("mailto:")
  const { locale } = usePageContext()

  console.log(
    href,
    isHref || !localise_pages ? href : `/${locale.toLowerCase()}${href}`
  )

  return (
    <Box
      as={!href?.includes("http") ? StyledLink : StyledAnchor}
      href={
        isHref || !localise_pages ? href : `/${locale.toLowerCase()}${href}`
      }
      onClick={
        isHref
          ? undefined
          : ev => {
              navigate(href)
              ev.preventDefault()
            }
      }
      {...rest}
    >
      {label || children}
    </Box>
  )
}

Anchor.propTypes = {}

Anchor.defaultProps = {
  href: "",
}

export default Anchor
