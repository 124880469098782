import React from "react"
import Anchor from "../../components/common/Anchor"
import Label from "./Label"

import styled from "styled-components"

const StyledAnchor = styled(Anchor)`
  &:hover {
    text-decoration: none;
  }
  box-shadow: none;
`

const LinkAnchor = ({ label, ...props }) => {
  return (
    <StyledAnchor
      {...props}
      weight="normal"
      styles={{ textDecoration: "none" }}
    >
      <Label>{label}</Label>
    </StyledAnchor>
  )
}

LinkAnchor.propTypes = {}

export default LinkAnchor
