import React from "react"
import { Button } from "grommet"
import Label from "./Label"

import styled from "styled-components"

const StyledButton = styled(Button)`
  &:hover {
    box-shadow: none;
  }
`

const LinkButton = ({ label, ...props }) => {
  return <StyledButton {...props} label={<Label>{label}</Label>} />
}

LinkButton.propTypes = {}

export default LinkButton
