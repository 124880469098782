import { Box } from "grommet"
import React from "react"
import ClientNavLink from "./ClientNavLink"
import SsrNavLink from "./SsrNavLink"

const NavLinks = ({ links }) => {
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? SsrNavLink : ClientNavLink
  return (
    <Box direction="row" align="center" gap="medium">
      {links.map(link => (
        <Component {...link} />
      ))}
    </Box>
  )
}

NavLinks.propTypes = {}

export default NavLinks
