import React from "react"
import { Heading, Text } from "grommet"

import Anchor from "../../components/common/Anchor"

const Link = ({ label, href, level = 3, ...rest }) => {
  return (
    <Anchor href={href}>
      <Heading margin="none" level={level} {...rest}>
        {label}
      </Heading>
    </Anchor>
  )
}

export default Link
