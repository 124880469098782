import React from "react"

import LinkGroup from "./LinkGroup"
import LinkAnchor from "./LinkAnchor"
import LinkButton from "./LinkButton"
import LanguageSwitch from "../LanguageSwitch"

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.button-link":
      return LinkButton
    case "shared.link":
      return LinkAnchor
    case "shared.link-group":
      return LinkGroup
    case "shared.language-switch":
      return LanguageSwitch
    default:
      return () => null
  }
}

const SsrNavLink = ({ label, href, strapi_component, primary, ...props }) => {
  const Component = getComponent(strapi_component)
  return (
    <Component
      {...props}
      focusIndicator={false}
      primary={primary}
      secondary={!primary}
      href={href}
      label={label}
      size="small"
    />
  )
}

export default SsrNavLink
