import React from "react"
import { Table as GrommetTable } from "grommet"

const Table = props => {
  return <GrommetTable {...props} />
}

Table.propTypes = {}

export default Table
