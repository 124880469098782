import React from "react"
import usePageContext from "./usePageContext"
import { navigate as gatsbyNavigate } from "gatsby"

const useChangeLanguage = () => {
  const { locale, path } = usePageContext()
  const strippedPath = path.replace(`/${locale.toLowerCase()}`, "")
  const handleChange = React.useCallback(
    newLocale => {
      gatsbyNavigate(`/${newLocale}${strippedPath}`)
    },
    [strippedPath]
  )
  return { handleChange }
}

export default useChangeLanguage
