import React from "react"
import Markdown from "../../components/common/Markdown"
import { Text } from "grommet"

const LinkText = ({ content, title, color }) => {
  return (
    <>
      <Text
        weight="bold"
        color={color}
        size="medium"
        margin={{ bottom: "medium" }}
      >
        {title}
      </Text>
      <Markdown
        textSize={{ all: "small" }}
        textMargin={{ all: { top: "none" } }}
        textColor={{ all: color }}
      >
        {content}
      </Markdown>
    </>
  )
}

LinkText.propTypes = {}

export default LinkText
