import { ResponsiveContext } from "grommet"
import React from "react"

export const useSize = () => {
  const size = React.useContext(ResponsiveContext)
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    setIsLoaded(true)
  }, [])

  return isLoaded ? size : "small"
}
