import React from "react"
import Ssr from "./Ssr"
import Client from "./Client"
import { Box, ResponsiveContext } from "grommet"

const FooterLinks = ({ footer_color, ...props }) => {
  const size = React.useContext(ResponsiveContext)
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? Ssr : Client
  return (
    <Box direction={size !== "small" ? "row" : "column"} gap="medium">
      <Component {...props} footer_color={footer_color || {}} />
    </Box>
  )
}

export default FooterLinks
