// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-strapi-article-locale-strapi-article-index-path-prefix-index-js": () => import("./../../../src/pages/{StrapiArticle.locale}/{StrapiArticle.index__path_prefix}/index.js" /* webpackChunkName: "component---src-pages-strapi-article-locale-strapi-article-index-path-prefix-index-js" */),
  "component---src-pages-strapi-article-locale-strapi-article-index-path-prefix-strapi-article-slug-js": () => import("./../../../src/pages/{StrapiArticle.locale}/{StrapiArticle.index__path_prefix}/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-strapi-article-locale-strapi-article-index-path-prefix-strapi-article-slug-js" */),
  "component---src-pages-strapi-category-locale-strapi-category-index-path-prefix-index-js": () => import("./../../../src/pages/{StrapiCategory.locale}/{StrapiCategory.index__path_prefix}/index.js" /* webpackChunkName: "component---src-pages-strapi-category-locale-strapi-category-index-path-prefix-index-js" */),
  "component---src-pages-strapi-category-locale-strapi-category-index-path-prefix-strapi-category-slug-js": () => import("./../../../src/pages/{StrapiCategory.locale}/{StrapiCategory.index__path_prefix}/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-strapi-category-locale-strapi-category-index-path-prefix-strapi-category-slug-js" */),
  "component---src-pages-strapi-contact-us-locale-strapi-contact-us-page-path-js": () => import("./../../../src/pages/{StrapiContactUs.locale}/{StrapiContactUs.page_path}.js" /* webpackChunkName: "component---src-pages-strapi-contact-us-locale-strapi-contact-us-page-path-js" */),
  "component---src-pages-strapi-homepage-locale-index-js": () => import("./../../../src/pages/{StrapiHomepage.locale}/index.js" /* webpackChunkName: "component---src-pages-strapi-homepage-locale-index-js" */),
  "component---src-pages-strapi-not-found-locale-404-js": () => import("./../../../src/pages/{StrapiNotFound.locale}/404.js" /* webpackChunkName: "component---src-pages-strapi-not-found-locale-404-js" */),
  "component---src-pages-strapi-static-locale-strapi-static-slug-js": () => import("./../../../src/pages/{StrapiStatic.locale}/{StrapiStatic.slug}.js" /* webpackChunkName: "component---src-pages-strapi-static-locale-strapi-static-slug-js" */),
  "component---src-pages-strapi-writer-locale-strapi-writer-index-path-prefix-strapi-writer-slug-js": () => import("./../../../src/pages/{StrapiWriter.locale}/{StrapiWriter.index__path_prefix}/{StrapiWriter.slug}.js" /* webpackChunkName: "component---src-pages-strapi-writer-locale-strapi-writer-index-path-prefix-strapi-writer-slug-js" */)
}

