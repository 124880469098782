import React, { lazy, Suspense } from "react"
import { Spinner } from "grommet"
import Wrapper from "./Wrapper"

const Client = ({ footer_links, footer_color: { text_color } }) => {
  return (
    <Suspense fallback={<Spinner />}>
      {footer_links.map(({ strapi_component, ...props }) => {
        const Component = getComponent(strapi_component)
        if (!Component) return null
        return (
          <Wrapper strapi_component={strapi_component}>
            <Component {...props} color={text_color} />
          </Wrapper>
        )
      })}
    </Suspense>
  )
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.link-group":
      return lazy(() => import("./LinkGroup"))
    case "shared.text-group":
      return lazy(() => import("./LinkText"))
    case "shared.link":
      return lazy(() => import("./Link"))
    default:
      return null
  }
}

export default Client
