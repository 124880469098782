import { Heading as GrommetHeading } from "grommet"

import React from "react"
import { getVal } from "./utils"

const Heading = ({
  children,
  level,
  textAlign,
  textSize,
  textColor,
  textMargin,
  ...props
}) => (
  <GrommetHeading
    level={level}
    {...props}
    fill={true}
    textAlign={getVal(`h${level}`, textAlign)}
    size={getVal(`h${level}`, textSize)}
    margin={getVal(`h${level}`, textMargin)}
    color={getVal(`h${level}`, textColor)}
  >
    {children}
  </GrommetHeading>
)

Heading.propTypes = {}

export default Heading
