import React from "react"
import { Box } from "grommet"

import Client from "./Client"
import Ssr from "./Ssr"

function Dynamic({ sections, localImages }) {
  if (!sections) return null
  const isSSR = typeof window === "undefined"
  return (
    <Box fill={true} gap="small">
      {isSSR ? (
        <Ssr sections={sections} localImages={localImages} />
      ) : (
        <Client sections={sections} localImages={localImages} />
      )}
    </Box>
  )
}

export default Dynamic
