import { compact, join } from "lodash"
import PropTypes from "prop-types"

export const getAuthorProperties = (authors = []) => {
  const primaryAuthor = authors[0] || {}
  let authorProfiles = []

  authorProfiles.push(
    primaryAuthor.website ? primaryAuthor.website : null,
    primaryAuthor.twitter
      ? `https://twitter.com/${primaryAuthor.twitter.replace(/^@/, ``)}/`
      : null,
    primaryAuthor.facebook
      ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, ``)}/`
      : null
  )

  authorProfiles = compact(authorProfiles)

  return {
    name: primaryAuthor.name || null,
    sameAsArray: authorProfiles.length
      ? `["${join(authorProfiles, `", "`)}"]`
      : null,
    image: primaryAuthor.profile_image || null,
  }
}

getAuthorProperties.defaultProps = {
  fetchAuthorData: false,
}

getAuthorProperties.PropTypes = {
  primaryAuthor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    profile_image: PropTypes.string,
    website: PropTypes.string,
    twitter: PropTypes.string,
    facebook: PropTypes.string,
  }).isRequired,
}

export const useJsonLd = ({ fullSeo, strapiGlobal, type, path, data }) => {
  const { defaultSeo, siteName, favicon, twitter, canonical } = strapiGlobal
  const { isArticle, isCategory, isAuthor, is404 } = type

  if (isArticle) {
    const article = data.strapiArticle
    const author = getAuthorProperties(article.authors)

    return {
      "@context": `https://schema.org/`,
      "@type": `Article`,
      author: {
        "@type": `Person`,
        name: author.name,
        image: author.image ? author.image : undefined,
        sameAs: author.sameAsArray ? author.sameAsArray : undefined,
      },
      keywords: article.categories.length
        ? article.categories.map(category => category.name).join(`, `)
        : undefined,
      headline: fullSeo.metaTitle,
      url: `${canonical}${path}`,
      datePublished: article.published_at,
      dateModified: article.updated_at,
      image: fullSeo.shareImage
        ? {
            "@type": `ImageObject`,
            url: fullSeo.shareImage.url,
            width: fullSeo.shareImage.width,
            height: fullSeo.shareImage.height,
          }
        : undefined,
      publisher: {
        "@type": `Organization`,
        name: fullSeo.metaTitle,
        logo: {
          "@type": `ImageObject`,
          url: favicon.url,
          width: 60,
          height: 60,
        },
      },
      description: fullSeo.metaDescription,
      mainEntityOfPage: {
        "@type": `WebPage`,
        "@id": canonical,
      },
    }
  }

  if (isAuthor) {
    const author = data.strapiWriter
    return {
      "@context": `https://schema.org/`,
      "@type": `Person`,
      name: author.name,
      sameAs: author.sameAsArray ? author.sameAsArray : undefined,
      url: `${canonical}${path}`,
      image: fullSeo.shareImage
        ? {
            "@type": `ImageObject`,
            url: fullSeo.shareImage.url,
            width: fullSeo.shareImage.width,
            height: fullSeo.shareImage.height,
          }
        : undefined,
      mainEntityOfPage: {
        "@type": `WebPage`,
        "@id": canonical,
      },
      description: fullSeo.metaDescription,
    }
  }

  const isSeries = !!isCategory

  return {
    "@context": `https://schema.org/`,
    "@type": isCategory ? `Series` : `WebSite`,
    url: `${canonical}${path}`,
    image: fullSeo.shareImage
      ? {
          "@type": `ImageObject`,
          url: fullSeo.shareImage.url,
          width: fullSeo.shareImage.width,
          height: fullSeo.shareImage.height,
        }
      : undefined,
    publisher: isSeries
      ? undefined
      : {
          "@type": `Organization`,
          name: fullSeo.metaTitle,
          logo: {
            "@type": `ImageObject`,
            url: favicon.url,
            width: 60,
            height: 60,
          },
        },
    mainEntityOfPage: {
      "@type": `WebPage`,
      "@id": canonical,
    },
    description: fullSeo.metaDescription,
  }
}
