import React from "react"

import {
  Anchor,
  Box,
  Footer as GrommetFooter,
  ResponsiveContext,
} from "grommet"
import { FacebookOption, Instagram, Twitter } from "grommet-icons"
import FooterLinks from "./FooterLinks"
import TextFooter from "./TextFooter"

const Footer = ({
  strapiGlobal: {
    footer_links,
    footer_color,
    expanded_footer: { show_facebook, show_instagram, show_twitter },
  },
}) => {
  const size = React.useContext(ResponsiveContext)

  const { background_color, text_color } = footer_color || {}

  return (
    <GrommetFooter
      direction="column"
      background={background_color || "dark-1"}
      pad={{ horizontal: "medium", vertical: "small" }}
      align="center"
    >
      <Box width="xlarge" direction="row-responsive" justify="between">
        <Box direction={"row"} gap="xxsmall" justify="center">
          {show_instagram && (
            <Anchor
              href="https://www.instagram.com/"
              icon={<Instagram color={text_color} />}
            />
          )}
          {show_facebook && (
            <Anchor
              href="https://www.facebook.com/"
              icon={<FacebookOption color={text_color} />}
            />
          )}
          {show_twitter && (
            <Anchor
              href="https://twitter.com/"
              icon={<Twitter color={text_color} />}
            />
          )}
        </Box>
        <FooterLinks footer_color={footer_color} footer_links={footer_links} />
      </Box>
      <Box width="xlarge">
        <TextFooter footer_color={footer_color} footer_links={footer_links} />
      </Box>
    </GrommetFooter>
  )
}

Footer.propTypes = {}

export default Footer
