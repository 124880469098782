import React from "react"
import PropTypes from "prop-types"
import Markdown from "../../components/common/Markdown"
import styled from "styled-components"
import { Box } from "grommet"

const StyledBox = styled(Box)`
  opacity: 0.3;
`
const TextFooter = ({ color, border_width, content }) => {
  return (
    <StyledBox align="center" gap="xsmall">
      <hr style={{ width: border_width }} />
      <Markdown textSize={{ all: "small" }} textAlign={{ all: "center" }}>
        {content}
      </Markdown>
    </StyledBox>
  )
}

TextFooter.propTypes = {}

export default TextFooter
