import { Spinner } from "grommet"
import React, { Suspense, lazy } from "react"

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.button-link":
      return lazy(() => import("./LinkButton"))
    case "shared.link":
      return lazy(() => import("./LinkAnchor"))
    case "shared.link-group":
      return lazy(() => import("./LinkGroup"))
    case "shared.language-switch":
      return lazy(() => import("../LanguageSwitch"))
    default:
      return () => null
  }
}

const ClientNavLink = ({
  label,
  href,
  strapi_component,
  primary,
  ...props
}) => {
  const Component = getComponent(strapi_component)

  return (
    <Suspense fallback={<Spinner />}>
      <Component
        {...props}
        focusIndicator={false}
        primary={primary}
        secondary={!primary}
        href={href}
        label={label}
        size="small"
      />
    </Suspense>
  )
}

export default ClientNavLink
