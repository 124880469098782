import React from "react"

import SsrNav from "./SsrNav"
import ClientNav from "./ClientNav"

const Nav = props => {
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? SsrNav : ClientNav
  return <Component {...props} />
}

Nav.propTypes = {}

export default Nav
