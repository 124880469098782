import React from "react"
import { Text as GrommetText } from "grommet"

import { getVal } from "./utils"

const Text = ({
  children,
  textAlign,
  textSize,
  textColor,
  textMargin,
  ...props
}) => (
  <GrommetText
    {...props}
    textAlign={getVal("span", textAlign)}
    size={getVal("span", textSize)}
    margin={getVal(`span`, textMargin)}
    color={getVal("span", textColor)}
  >
    {children}
  </GrommetText>
)

Text.propTypes = {}

export default Text
