import React from "react"
import { TableRow as GrommetTableRow } from "grommet"

const TableRow = props => {
  return <GrommetTableRow {...props} />
}

TableRow.propTypes = {}

export default TableRow
