import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Header as GrommetHeader, Box, Button } from "grommet"
import Anchor from "../components/common/Anchor"
import Nav from "./Nav"

const Header = ({ strapiGlobal }) => {
  const {
    nav_links,
    nav_bar: {
      logo: { localFile, caption },
    },
  } = strapiGlobal
  const image = getImage(localFile.childImageSharp)

  return (
    <GrommetHeader
      pad={{ horizontal: "medium", vertical: "medium" }}
      margin={{ vertical: "small" }}
      height="xxsmall"
      overflow="hidden"
    >
      <Button
        plain
        as={Anchor}
        focusIndicator={false}
        href="/"
        icon={<GatsbyImage image={image} alt="" />}
      />
      <Nav nav_links={nav_links} />
    </GrommetHeader>
  )
}

export default Header

const query = graphql`
  query {
    strapiGlobal {
      nav_links
      nav_bar {
        logo {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
                height: 75
                width: 150
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
