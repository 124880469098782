import { Spinner } from "grommet"

import React, { Suspense, lazy } from "react"

const Client = ({ sections, localImages }) => {
  return (
    <Suspense fallback={<Spinner />}>
      {sections.map((section, i) => {
        const Component = getComponent(section.strapi_component)

        return (
          <Component
            key={`${section.strapi_component}_${i}`}
            {...section}
            localImages={localImages[section.strapi_component]}
          />
        )
      })}
    </Suspense>
  )
}

Client.propTypes = {}

export default Client

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.sticky-button-link":
      return lazy(() => import("./StickyButton"))
    // case "sections.column-set":
    //   return lazy(() => import("../Sections/ColumnSet"))
    // case "sections.banner":
    //   return lazy(() => import("../Sections/Banner"))
    // case "sections.email-collection":
    //   return lazy(() => import("../Sections/EmailCollection"))
    // case "sections.picture-grid":
    //   return lazy(() => import("../Sections/PictureGrid"))
    // case "sections.hero":
    //   return lazy(() => import("../Sections/Hero"))
    // case "sections.testimonials":
    //   return lazy(() => import("../Sections/Testimonials"))
    // case "sections.cta":
    //   return lazy(() => import("../Sections/CTA"))
    // case "sections.blockquote":
    //   return lazy(() => import("../Sections/Blockquote"))
    // case "sections.button-list":
    //   return lazy(() => import("../Sections/ButtonList"))
    // case "sections.featured-articles":
    //   return lazy(() => import("../Sections/ArticleFeature"))
    // case "sections.title":
    //   return lazy(() => import("../Sections/Title"))
    // case "sections.split-screen":
    //   return lazy(() => import("../Sections/SplitScreen"))
    // case "sections.pricing":
    //   return lazy(() => import("../Sections/PricingCards"))
    default:
      return () => null
  }
}
