import React from "react"
import { TableBody as GrommetTableBody } from "grommet"

const TableBody = props => {
  return <GrommetTableBody {...props} />
}

TableBody.propTypes = {}

export default TableBody
