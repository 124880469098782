import React from "react"

export const PageContext = React.createContext()

const PageContextProvider = ({ children, data = {}, globalNodes = [] }) => {
  const allLocales = globalNodes.map(n => n.locale)
  return (
    <PageContext.Provider value={{ ...data, allLocales }}>
      {children}
    </PageContext.Provider>
  )
}

PageContextProvider.propTypes = {}

export default PageContextProvider
