import React from "react"

import { ResponsiveContext } from "grommet"
import ClientSmall from "./ClientSmall"
import ClientLarge from "./ClientLarge"

const SsrNav = props => {
  const size = React.useContext(ResponsiveContext)
  return size === "small" ? (
    <ClientSmall {...props} />
  ) : (
    <ClientLarge {...props} />
  )
}

SsrNav.propTypes = {}

export default SsrNav
