import React from "react"

import { Markdown as GrommetMarkdown } from "grommet"

import InlineAnchor from "../InlineAnchor"
import Image from "./Image"
import Text from "./Text"
import Paragraph from "./Paragraph"
import Heading from "./Heading"
import Table from "./Table"
import TableBody from "./TableBody"
import TableFooter from "./TableFooter"
import TableCell from "./TableCell"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"

const SsrMarkdown = ({ children, ...props }) => {
  return (
    <GrommetMarkdown
      components={{
        h1: {
          component: Heading,
          props,
        },
        h2: {
          component: Heading,
          props,
        },
        h3: {
          component: Heading,
          props,
        },
        h4: {
          component: Heading,
          props,
        },
        h5: {
          component: Heading,
          props,
        },
        p: {
          component: Paragraph,
          props,
        },
        span: {
          component: Text,
          props,
        },
        a: { component: InlineAnchor, props },
        img: {
          component: Image,
          props,
        },
        table: { component: Table, props },
        td: { component: TableCell, props },
        tbody: { component: TableBody, props },
        tfoot: { component: TableFooter, props },
        th: { component: TableCell, props },
        thead: { component: TableHeader, props },
        tr: { component: TableRow, props },
      }}
    >
      {children}
    </GrommetMarkdown>
  )
}

SsrMarkdown.propTypes = {}

export default SsrMarkdown
