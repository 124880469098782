import React from "react"
import { Box } from "grommet"

const Wrapper = ({ children }) => {
  return (
    <Box gap="small" pad="small" width={{ max: "medium" }}>
      {children}
    </Box>
  )
}

Wrapper.propTypes = {}

export default Wrapper
