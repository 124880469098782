import React from "react"

import SideBar from "../SideBar"
import { Button } from "grommet"
import { Menu } from "grommet-icons"

const ClientSmall = ({ nav_links }) => {
  const [sidebar, setSidebar] = React.useState(false)

  return (
    <>
      {sidebar && <SideBar setSidebar={setSidebar} links={nav_links} />}
      <Button
        plain
        icon={<Menu color="brand" />}
        a11yTitle="Navigation Menu"
        focusIndicator={false}
        onClick={() => setSidebar(true)}
      />
    </>
  )
}

ClientSmall.propTypes = {}

export default ClientSmall
