import React from "react"
import { Box, List, Heading } from "grommet"
import Link from "./Link"

const LinkGroup = ({ title, link }) => {
  const [show, setShow] = React.useState(false)
  return (
    <>
      <Box onClick={() => setShow(state => !state)} focusIndicator={false}>
        <Heading level={3} margin="none">
          {title}
        </Heading>
      </Box>
      {show && (
        <List
          data={link}
          border={false}
          primaryKey={({ label, href }) => (
            <Link level={4} href={href} label={label} />
          )}
        />
      )}
    </>
  )
}

export default LinkGroup
