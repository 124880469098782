import React from "react"
import { List } from "grommet"
import ButtonLink from "./ButtonLink"
import Link from "./Link"
import LinkGroup from "./LinkGroup"
import LanguageSwitch from "../LanguageSwitch"

const SsrSidebarList = ({ links }) => {
  return (
    <List
      data={links}
      border={false}
      pad="medium"
      primaryKey={props => {
        const Component = getComponent(props.strapi_component)
        return <Component {...props} />
      }}
    />
  )
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.button-link":
      return ButtonLink
    case "shared.link":
      return Link
    case "shared.link-group":
      return LinkGroup
    case "shared.language-switch":
      return LanguageSwitch
    default:
      return () => null
  }
}

SsrSidebarList.propTypes = {}

export default SsrSidebarList
