import React, { lazy, Suspense } from "react"
import { Spinner } from "grommet"

const Client = ({ ...props }) => {
  const Component = lazy(() => import("./TextFooter"))
  return (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  )
}

export default Client
