import React from "react"

import LinkGroup from "./LinkGroup"
import LinkText from "./LinkText"
import Link from "./Link"
import Wrapper from "./Wrapper"

const Ssr = ({ footer_links, footer_color: { text_color } }) => {
  return footer_links.map(({ strapi_component, ...props }) => {
    const Component = getComponent(strapi_component)
    if (!Component) return null
    return (
      <Wrapper strapi_component={strapi_component}>
        <Component {...props} color={text_color} />
      </Wrapper>
    )
  })
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.link-group":
      return LinkGroup
    case "shared.text-group":
      return LinkText
    case "shared.link":
      return Link
    default:
      return null
  }
}

export default Ssr
