import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Main } from "grommet"
import Footer from "./Footer"
import Header from "./Header"

import Seo from "./Seo"
import PageContextProvider from "../hooks/usePageContext/PageContextProvider"
import Dynamic from "./Dynamic"

const Layout = ({ children, data, path, pageContext = {}, ...rest }) => {
  const { slug, id, name, locale } = pageContext
  const isArticle = path.includes("/article/") && slug
  const isCategory = path.includes("/category/") && name
  const isAuthor = path.includes("/author/") && id
  const isLanding = path.includes("/landing/")
  const is404 = path.includes("/404/")
  const {
    allStrapiGlobal: { nodes },
  } = useStaticQuery(query)
  const strapiGlobal = nodes.find(
    s => s.locale.toLowerCase() === locale.toLowerCase()
  )
  const { dynamic, localImage_sticky_button } = strapiGlobal

  const localImages = {
    "shared.sticky-button-link": localImage_sticky_button,
  }

  return (
    <PageContextProvider data={{ ...pageContext, path }} globalNodes={nodes}>
      <Box height={{ min: "100vh" }}>
        <Seo
          lang={locale}
          data={data}
          type={{ isArticle, isCategory, isAuthor, isLanding, is404 }}
          path={path}
          strapiGlobal={strapiGlobal}
        />
        <Header strapiGlobal={strapiGlobal} height="xsmall"></Header>
        <Main flex="grow" margin={{ top: "large", bottom: "large" }}>
          {children}
          <Dynamic sections={dynamic} localImages={localImages} />
        </Main>
        <Footer strapiGlobal={strapiGlobal} />
      </Box>
    </PageContextProvider>
  )
}

Layout.propTypes = {}

export default Layout

const query = graphql`
  query {
    allStrapiGlobal {
      nodes {
        locale
        siteName
        canonical
        dynamic
        localImage_sticky_button {
          id
          url
          name
          childImageSharp {
            gatsbyImageData(
              width: 30
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        favicon {
          url
        }
        nav_links
        nav_bar {
          logo {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: OUTSIDE, cropFocus: ATTENTION }
                  height: 75
                  width: 150
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        footer_links
        expanded_footer {
          show_facebook
          show_instagram
          show_twitter
        }
        footer_color {
          background_color
          text_color
        }
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            width
            height
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
