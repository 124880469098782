import React from "react"
import Ssr from "./Ssr"
import Client from "./Client"

const TextFooter = ({ footer_links, footer_color: { text_color } }) => {
  const footer = footer_links.find(
    l => l.strapi_component === "shared.text-footer"
  )
  if (!footer) return null
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? Ssr : Client
  return <Component {...footer} color={text_color} />
}

export default TextFooter
