import React from "react"
import { Layer, Box, Button } from "grommet"
import { Close } from "grommet-icons"

import SsrSidebarList from "./SsrSidebarList"
import ClientSidebarList from "./ClientSidebarList"

const SideBar = ({ setSidebar, ...props }) => {
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? SsrSidebarList : ClientSidebarList
  return (
    <Layer full="vertical" position="right" responsive={false}>
      <Box fill width={{ min: "small" }}>
        <Box direction="row" align="end" as="header" justify="end">
          <Button
            margin="xsmall"
            icon={<Close size="medium" />}
            focusIndicator={false}
            onClick={() => setSidebar(false)}
          />
        </Box>
        <Box flex overflow="auto" pad="medium">
          <Component {...props} />
        </Box>
      </Box>
    </Layer>
  )
}

SideBar.propTypes = {}

export default SideBar
