import React from "react"

import { Box, Text } from "grommet"
import Link from "./Link"

const LinkGroup = ({ title, link, color }) => {
  return (
    <>
      <Text
        weight="bold"
        color={color}
        size="medium"
        margin={{ bottom: "medium" }}
      >
        {title}
      </Text>
      <Box gap="small">
        {link.map(_link => (
          <Link {..._link} color={color} />
        ))}
      </Box>
    </>
  )
}

export default LinkGroup
