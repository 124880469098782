import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "grommet"

const getIcon = (icon, localImages) => {
  if (!icon) return undefined
  const rawImage = localImages.find(i => i.url === icon.url)
  const image = getImage(rawImage)
  return <GatsbyImage image={image} alt={icon.alternativeText} />
}

const StickyButton = ({ icon, localImages, ...props }) => {
  return (
    <Button
      focusIndicator={false}
      {...props}
      icon={getIcon(icon, localImages)}
      target="_blank"
      style={{
        position: "fixed",
        bottom: "2%",
        right: "3%",
        opacity: "0.93",
      }}
    />
  )
}

StickyButton.propTypes = {}

export default StickyButton
