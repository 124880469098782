import React from "react"
import NavLinks from "../Navlinks"
import { Box } from "grommet"

const ClientLarge = ({ nav_links }) => {
  return (
    <Box direction="row" gap="small">
      <NavLinks links={nav_links} />
    </Box>
  )
}

ClientLarge.propTypes = {}

export default ClientLarge
