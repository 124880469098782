import React from "react"
import { TableFooter as GrommetTableFooter } from "grommet"

const TableFooter = props => {
  return <GrommetTableFooter {...props} />
}

TableFooter.propTypes = {}

export default TableFooter
