import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useMetaTags } from "./useMetaTags"
import { useJsonLd } from "./useJsonLd"

const getSeo = data => {
  const [strapiKey, pageData] =
    Object.entries(data).find(
      ([key, values]) => !Array.isArray(values) && typeof values === "object"
    ) || []
  return pageData?.seo || { shareImage: {} }
}

const Seo = ({ data = {}, type = {}, path = "", strapiGlobal, lang }) => {
  const { defaultSeo, siteName, favicon, twitter, canonical } = strapiGlobal

  const seoData = getSeo(data)
  // Merge default and page-specific Seo values
  const fullSeo = {
    ...defaultSeo,
    ...seoData,
    shareImage: { ...defaultSeo.shareImage, ...seoData.shareImage },
  }

  const metaTags = useMetaTags({ strapiGlobal, fullSeo, type, path, data })
  const jsonLd = useJsonLd({ strapiGlobal, fullSeo, type, path, data })

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      htmlAttributes={{ lang }}
      link={[{ rel: "canonical", href: [canonical, path].join() }]}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        },
        // {
        //   rel: "stylesheet",
        //   href: "https://fonts.googleapis.com/css?family=Staatliches",
        // },
        // {
        //   rel: "stylesheet",
        //   href:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/css/uikit.min.css",
        // },
      ]}
      // script={[
      //   {
      //     src:
      //       "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js",
      //   },
      //   {
      //     src:
      //       "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/js/uikit-icons.min.js",
      //   },
      //   {
      //     src: "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.js",
      //   },
      // ]}
      meta={metaTags}
    >
      {jsonLd && (
        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      )}
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
