import React from "react"
import { Box, Text, CheckBox } from "grommet"
import usePageContext from "../hooks/usePageContext"
import useChangeLanguage from "../hooks/useChangeLanguage"

const LanguageSwitch = ({ language_1, language_2 }) => {
  const { allLocales, locale } = usePageContext()
  const { handleChange } = useChangeLanguage()

  return (
    <Box direction="row" gap="xsmall" align="center">
      <Text size="xsmall">{language_1.toUpperCase()}</Text>
      <CheckBox
        onChange={e =>
          handleChange(e.target.checked ? language_2.toLowerCase() : language_1)
        }
        toggle
        checked={locale === language_2}
      />
      <Text size="xsmall">
        {language_2.includes("-")
          ? language_2.split("-")[1].toUpperCase()
          : language_2.toUpperCase()}
      </Text>
    </Box>
  )
}

LanguageSwitch.propTypes = {}

export default LanguageSwitch
