import { Paragraph as GrommetParagraph } from "grommet"

import React from "react"
import { getVal } from "./utils"

const Paragraph = ({
  children,
  textAlign,
  textSize,
  textMargin,
  textColor,
  ...props
}) => (
  <GrommetParagraph
    {...props}
    fill={true}
    textAlign={getVal("p", textAlign)}
    size={getVal("p", textSize)}
    margin={getVal(`p`, textMargin)}
    color={getVal("p", textColor)}
  >
    {children}
  </GrommetParagraph>
)

Paragraph.propTypes = {}

export default Paragraph
