import React from "react"
import { Grommet } from "grommet"
// import theme from "./theme"

import "./normalize.css"
import { useStaticQuery, graphql } from "gatsby"

const RootWrapper = ({ children, ...rest }) => {
  const {
    allStrapiGlobal: { nodes },
  } = useStaticQuery(graphql`
    query {
      allStrapiGlobal {
        nodes {
          theme
        }
      }
    }
  `)

  const { theme } = nodes[0]
  return (
    <Grommet fill={"true"} theme={theme}>
      {children}
    </Grommet>
  )
}

RootWrapper.propTypes = {}

export default RootWrapper
