import React from "react"

import Anchor from "../../components/common/Anchor"
import { Text } from "grommet"

const Link = ({ href, label, color }) => {
  return (
    <Text as={Anchor} href={href} size="small" color={color}>
      {label}
    </Text>
  )
}

Link.propTypes = {}

export default Link
