import React from "react"
import { TableCell as GrommetTableCell } from "grommet"

const TableCell = props => {
  return <GrommetTableCell {...props} />
}

TableCell.propTypes = {}

export default TableCell
