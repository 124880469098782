export const useMetaTags = ({ fullSeo, strapiGlobal, type, path }) => {
  const { defaultSeo, siteName, favicon, twitter, canonical } = strapiGlobal
  const { isArticle, isCategory, is404 } = type

  const tags = []
  if (fullSeo.noIndex) {
    tags.push({
      name: "robots",
      content: "noindex",
    })
  }
  if (canonical) {
    tags.push(
      {
        property: "og:url",
        content: `${canonical}${path}`,
      },
      {
        property: "twitter:url",
        content: `${canonical}${path}`,
      }
    )
  }
  if (twitter) {
    tags.push(
      {
        property: "twitter:site",
        content: `https://twitter.com/${twitter.replace(/^@/, ``)}/`,
      },
      {
        property: "twitter:creator",
        content: twitter,
      }
    )
  }
  if (fullSeo.metaTitle) {
    tags.push(
      {
        property: "og:title",
        content: fullSeo.metaTitle,
      },
      {
        name: "twitter:title",
        content: fullSeo.metaTitle,
      },
      {
        name: "og:site_name",
        content: fullSeo.metaTitle,
      }
    )
  }
  if (fullSeo.metaDescription) {
    tags.push(
      {
        name: "description",
        content: fullSeo.metaDescription,
      },
      {
        property: "og:description",
        content: fullSeo.metaDescription,
      },
      {
        name: "twitter:description",
        content: fullSeo.metaDescription,
      }
    )
  }

  if (fullSeo.shareImage) {
    const imageUrl = fullSeo.shareImage.url
    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    )
  }

  if (fullSeo.author) {
    tags.push({
      property: "og:type",
      content: "profile",
    })
  }

  if (fullSeo.strapiArticle) {
    const primaryAuthor =
      fullSeo.strapiArticle?.author && fullSeo.strapiArticle?.author[0]
    const primaryTag =
      fullSeo.strapiArticle?.categories && fullSeo.strapiArticle?.categories[0]
    tags.push(
      {
        property: "og:type",
        content: "article",
      },
      {
        property: "article:published_time",
        content: fullSeo.strapiArticle.published_at,
      },
      {
        property: "article:modified_time",
        content: fullSeo.strapiArticle.updated_at,
      }
    )

    if (primaryAuthor) {
      tags.push(
        {
          property: "article:author",
          content: primaryAuthor.name,
        },
        {
          property: "twitter:label1",
          content: "Written by",
        },
        {
          property: "twitter:data1",
          content: primaryAuthor.name,
        },
        {
          property: "twitter:label2",
          content: "Filed under",
        },
        {
          property: "twitter:data2",
          content: primaryTag.name,
        }
      )
      fullSeo.strapiArticle.categories.forEach(category => {
        tags.push({
          property: "article:tag",
          content: category.name,
        })
      })
    }
  }
  tags.push({ name: "twitter:card", content: "summary_large_image" })
  return tags
}
