import React from "react"

import { Box } from "grommet"
import ClientMarkdown from "./ClientMarkdown"
import SsrMarkdown from "./SsrMarkdown"

const Markdown = ({ pad, margin, align, justify, ...props }) => {
  const isSSR = typeof window === "undefined"
  const Component = isSSR ? SsrMarkdown : ClientMarkdown
  return (
    <Box align={align} justify={justify} pad={pad} margin={margin}>
      <Component {...props} />
    </Box>
  )
}

Markdown.propTypes = {}

export default Markdown
