import { Spinner } from "grommet"
import React, { Suspense, lazy } from "react"
import { useSize } from "../../hooks/useSize"

const ClientNav = props => {
  const size = useSize()
  const Component =
    size === "small"
      ? lazy(() => import("./ClientSmall"))
      : lazy(() => import("./ClientLarge"))

  return (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  )
}

ClientNav.propTypes = {}

export default ClientNav
