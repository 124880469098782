import React, { Suspense, lazy } from "react"
import { List, Spinner } from "grommet"

const ClientSidebarList = ({ links, ...props }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <List
        data={links}
        border={false}
        pad="medium"
        primaryKey={props => {
          const Component = getComponent(props.strapi_component)
          return <Component {...props} />
        }}
      />
    </Suspense>
  )
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.button-link":
      return lazy(() => import("./ButtonLink"))
    case "shared.link":
      return lazy(() => import("./Link"))
    case "shared.link-group":
      return lazy(() => import("./LinkGroup"))
    case "shared.language-switch":
      return lazy(() => import("../LanguageSwitch"))
    default:
      return () => null
  }
}

ClientSidebarList.propTypes = {}

export default ClientSidebarList
