import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const Image = ({ src, alt, localImages }) => {
  const localFile = localImages.find(image => image.url === src)
  const image = getImage(localFile)
  return <GatsbyImage image={image} alt={alt} />
}

Image.propTypes = {}

export default Image
