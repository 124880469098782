import { Markdown as GrommetMarkdown, Spinner } from "grommet"

import React from "react"

import { Suspense, lazy } from "react"

const ClientMarkdown = ({ children, ...props }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <GrommetMarkdown
        components={{
          h1: {
            component: lazy(() => import("./Heading")),
            props,
          },
          h2: {
            component: lazy(() => import("./Heading")),
            props,
          },
          h3: {
            component: lazy(() => import("./Heading")),
            props,
          },
          h4: {
            component: lazy(() => import("./Heading")),
            props,
          },
          h5: {
            component: lazy(() => import("./Heading")),
            props,
          },
          p: {
            component: lazy(() => import("./Paragraph")),
            props,
          },
          span: {
            component: lazy(() => import("./Text")),
            props,
          },
          a: { component: lazy(() => import("../InlineAnchor")), props },
          img: {
            component: lazy(() => import("./Image")),
            props,
          },
          li: {
            component: lazy(() => import("./ListItem")),
            props,
          },
          table: { component: import("./Table"), props },
          td: { component: import("./TableCell"), props },
          tbody: { component: import("./TableBody"), props },
          tfoot: { component: import("./TableFooter"), props },
          th: { component: import("./TableCell"), props },
          thead: { component: import("./TableHeader"), props },
          tr: { component: import("./TableRow"), props },
        }}
      >
        {children}
      </GrommetMarkdown>
    </Suspense>
  )
}

ClientMarkdown.propTypes = {}

export default ClientMarkdown
