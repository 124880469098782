// custom typefaces
import "./gatsby-fonts"

import React from "react"
import Layout from "./src/global/Layout"
import RootWrapper from "./src/global/RootWrapper"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <RootWrapper {...props}>{element}</RootWrapper>
}
