import React from "react"
import { Button } from "grommet"

import Anchor from "../../components/common/Anchor"

const ButtonLink = ({ label, primary, href }) => {
  return (
    <Button
      as={Anchor}
      label={label}
      primary={primary}
      href={href}
      alignSelf="center"
    />
  )
}

ButtonLink.propTypes = {}

export default ButtonLink
