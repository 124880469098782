import React from "react"
import StickyButton from "./StickyButton"

function Ssr({ sections, localImages }) {
  return sections.map((section, i) => {
    const Component = getComponent(section.strapi_component)
    return (
      <Component
        key={`${section.strapi_component}_${i}`}
        {...section}
        localImages={localImages[section.strapi_component]}
      />
    )
  })
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "shared.sticky-button-link":
      return StickyButton
    // case "sections.column-set":
    //   return ColumnSet
    // case "sections.banner":
    //   return Banner
    // case "sections.email-collection":
    //   return EmailCollection
    // case "sections.picture-grid":
    //   return PictureGrid
    // case "sections.hero":
    //   return Hero
    // case "sections.testimonials":
    //   return Testimonials
    // case "sections.cta":
    //   return CTA
    // case "sections.blockquote":
    //   return Blockquote
    // case "sections.button-list":
    //   return ButtonList
    // case "sections.featured-articles":
    //   return ArticleFeature
    // case "sections.title":
    //   return Title
    // case "sections.split-screen":
    //   return SplitScreen
    // case "sections.pricing":
    //   return PricingCards
    default:
      return () => null
  }
}

export default Ssr
